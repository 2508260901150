import { Integrations, SpringLogger } from "@spring-global/spring-logger-react";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { App } from "./App";
import { ServiceConfig } from "./config/ServiceConfig";
import "./index.scss";
import { intercept } from "./services/util/HTTPInterceptor";

SpringLogger.init(new Integrations.ReactSentryLogger(ServiceConfig.spring_logger || ''));

intercept();

ReactDOM.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <App />
        </DndProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
