import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import "../App.scss";
import { useAppState } from "../context/StateProvider";


type PublicProp = {
    restricted?: boolean;
}

const PublicRoute = ({ restricted = false, component: Component, ...rest }: RouteProps & PublicProp) => {
    const { identity } = useAppState();

    if (!Component)
        return null;

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <div className="app-body">
            <Route
                {...rest}
                render={
                    props =>
                        (identity?.isAuthenticated) && restricted ?
                            <Redirect to="/" />
                            :
                            <React.Fragment>
                                <Component {...props} />
                            </React.Fragment>
                }
            />
        </div>
    );
};

export default PublicRoute;
