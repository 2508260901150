import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as SpringGlobalTextLogo } from "./../../assets/icons/svg/spring-global-text-logo.svg";
import { ReactComponent as SpringGlobalGlobeLogo } from "./../../assets/icons/svg/spring-global-globe-logo.svg";

import "./Sidebar.scss";
import { SidebarData } from "./SidebarData";
import { ExitToApp, ExitToAppOutlined } from "@material-ui/icons";
import { useMediaQuery } from "@material-ui/core";
import { Resources } from "../../resources/Resources";

type Props = {
  overlay: boolean;
  className?: string;
};

function Navbar(props: Props) {
  const { overlay, className } = props;
  const [expanded, setExpanded] = useState(false);
  const toggleSidebar = () => setExpanded(!expanded);
  const md = useMediaQuery('(max-width: 900px)');

  return (
    <div className={className}>
      <nav
        className={`sidebar ${expanded ? "expanded" : ""} ${overlay ? "overlay" : ""
          }`}
      >
        <ul className="sidebar-items">
          <div className="sidebar-spring-logo">
            {expanded ? <SpringGlobalTextLogo /> : <SpringGlobalGlobeLogo />}
          </div>
          {SidebarData.map((item, index) => {
            return item.md || md ? (
              <li key={index} className="sidebar-item">
                <NavLink exact={true} to={item.path} activeClassName="active">
                  {item.icon}
                  {expanded ? (
                    <span className="sidebar-item-text">{item.title}</span>
                  ) : null}
                </NavLink>
              </li>
            ) : null;
          })}
          <li
            className={`sidebar-item ${expanded ? "expanded" : ""}`}
            onClick={toggleSidebar}
          >
            <NavLink to="#">
              {expanded ? (
                <ArrowBackIosIcon />
              ) : (
                <ArrowForwardIosOutlinedIcon />
              )}
            </NavLink>
          </li>
          <li
            className={`sidebar-item last-item-bottom ${expanded ? "expanded" : ""}`}
            onClick={toggleSidebar}
          >
            <NavLink to="/logout">
              {expanded ? (
                <React.Fragment>
                  <ExitToApp />
                  <span className="sidebar-item-text">{Resources.get("Menu", "Logout", "label")}</span>
                </React.Fragment>
              ) : (
                <ExitToAppOutlined />
              )}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
