import axios from "axios";
import { Endpoints } from "../config/endpoints";
import { getLastFourDigits } from "../services/util/DataUtils";
import { ICard, IPaymentMethod, IPaymentMethodRequest } from "./PaymentMethod";

export class ZoopPayment {
  paymentMethod: IPaymentMethod;

  constructor(args: IPaymentMethod) {
    this.paymentMethod = args;
  }

  async addPaymentMethod(): Promise<IPaymentMethodRequest> {
    return new Promise((resolve, reject) => {
      const marketPlaceId = process.env.REACT_APP_ZOOP_MARKET_PLACE_ID;
      const zpk = process.env.REACT_APP_ZOOP_ZPK;
      axios
        .post(
          `${Endpoints.getZoopEndpoint()}/${marketPlaceId}/cards/tokens`,
          this.createBody(),
          {
            auth: {
              username: zpk!,
              password: "",
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => resolve(this.formatResponse(res.data)))
        .catch((err: Error) => reject(err));
    });
  }

  private createBody(): any {
    const cardData: ICard = this.paymentMethod.card!;
    const [month, year] = cardData.expirationDate.split("/");
    return {
      card_number: cardData.number,
      expiration_month: month,
      expiration_year: year,
      holder_name: cardData.name,
      security_code: cardData.cvv,
    };
  }

  private formatResponse(responseData: any): IPaymentMethodRequest {
    const lastFour = getLastFourDigits(
      this.paymentMethod.card?.number || "0000"
    );
    return {
      id: null,
      userId: this.paymentMethod.userId!,
      paymentTypeCode: this.paymentMethod.paymentMethodType!.code,
      paymentMaskedNumber: lastFour![0],
      token: responseData.id,
      expirationYear: responseData.card.expiration_year,
      expirationMonth: responseData.card.expiration_month,
      cardLogo: responseData.card.card_brand,
      isDefault: this.paymentMethod.card
        ? this.paymentMethod.card.isDefault
        : false,
      status: "ACT",
      creationDate: responseData.created_at,
      platformCode: this.paymentMethod.platform.toString(),
    };
  }
}
