import { Resources } from "../../resources/Resources";

export enum ArrayOrder {
    ASC = 1,
    DESC = -1
}
/**
 * @description
 * Function to get the last four digits
 * @param number Base number
 * @returns Last four digits
 */
export const getLastFourDigits = (number: string) => RegExp(/.{4}$/gm).exec(number);

export function sortArrayByOrder<T, K extends keyof T>(
    array: T[],
    order: ArrayOrder = 1,
    param: K | "$index" = "$index"
): Omit<T & { $index: number }, "$index">[] {
    return array.map((obj, $index) => ({ ...obj, $index }))
        .sort((a, b) => ((a[param] > b[param]) ? order : order * -1))
        .map(({ $index, ...obj }) => obj);
}

/**
 * @description
 * Function to convert the payment method type code to a single string
 * @returns Payment method type
 */
export const getCardType = (type: string) => {
    switch (type) {
        case "CCARD":
            return Resources.get("PaymentMethod", "CCARD", "label");
        case "DCARD":
            return Resources.get("PaymentMethod", "DCARD", "label");
        case "ACH":
            return Resources.get("PaymentMethod", "ACH", "label");
        case "BOLET":
            return Resources.get("PaymentMethod", "BOLET", "label");
        default:
            return "";
    }
}
