import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { StateProvider } from "./context/StateProvider";
import Div100vh from 'react-div-100vh';
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import WorldpayPage from "./pages/Worldpay/Worldpay";

const Wallet = React.lazy(() => import('./pages/Wallet/Wallet'));
const Login = React.lazy(() => import('./pages/Login/Login'));
const PaymentAuthorization = React.lazy(() => import('./pages/PaymentAuthorization/PaymentAuthorization'));
const TransactionToPay = React.lazy(() => import('./pages/TransactionToPay/TransactionToPay'));
const Logout = React.lazy(() => import('./pages/Logout/Logout'));
const Transactions = React.lazy(() => import('./pages/Wallet/Transactions/Transactions'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword/ChangePassword'));
const AddPaymentMethod = React.lazy(() => import('./pages/AddPaymentMethod/AddPaymentMethod'));
const AddPaymentMethodForm = React.lazy(() => import('./pages/AddPaymentMethodForm/AddPaymentMethodForm'))
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword/ForgotPassword'))
const RecoverPassword = React.lazy(() => import('./pages/RecoverPassword/RecoverPassword'))

export const App = () => {

  return (
    <Div100vh>
      <div className="app">
        <StateProvider>
          <React.Suspense fallback={<span></span>}>
            <Router>
              <Switch>
                <PublicRoute restricted={false} path="/login" component={Login} />
                <PublicRoute restricted={false} path="/changePassword" component={ChangePassword} />
                <PublicRoute restricted={false} path="/worldpay/:type" component={WorldpayPage} />
                <PublicRoute restricted={false} path="/forgotPassword" component={ForgotPassword} />
                <PublicRoute restricted={false} path="/recoverPassword" component={RecoverPassword} />
                <PublicRoute restricted={false} path="/transactionToPay/:identifier"  component={TransactionToPay} />
                <PrivateRoute path="/" component={Wallet} exact showLeftSidebar={true} />
                <PrivateRoute path="/paymentAuthorization/:transactionId" exact showLeftSidebar={false} component={PaymentAuthorization} />
                <PrivateRoute path="/wallet" component={Wallet} exact showLeftSidebar={true} />
                <PrivateRoute path="/addPaymentMethod" showLeftSidebar={true} component={AddPaymentMethod} exact />
                <PrivateRoute path="/addPaymentMethodForm" showLeftSidebar={true} component={AddPaymentMethodForm} exact />
                <PrivateRoute path="/transactions" component={Transactions} exact showLeftSidebar={true} />
                <PrivateRoute path="/logout" component={Logout} exact showLeftSidebar={true} />
                <PrivateRoute showLeftSidebar={true} />
              </Switch>
            </Router>
          </React.Suspense>
        </StateProvider>
      </div>

    </Div100vh>

  );
};
