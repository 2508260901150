import React from "react";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { ReactComponent as WalletIcon } from "./../../assets/icons/svg/wallet-menu.svg";
import { ReactComponent as TransactionsIcon } from "./../../assets/icons/svg/transactions-menu.svg";
import { Resources } from "../../resources/Resources";


export const SidebarData = [
  {
    title: Resources.get("Menu", "Home", "label"),
    path: "/",
    md: true,
    icon: <HomeOutlinedIcon />,
  },
  {
    title: Resources.get("Menu", "Wallet", "label"),
    path: "/wallet",
    md: true,
    icon: <WalletIcon />,
  },
  {
    title: "Transactions",
    path: "/transactions",
    md: false,
    icon: <TransactionsIcon />,
  }
];
