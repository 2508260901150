import { SpringLogger } from "@spring-global/spring-logger-react";
import axios from "axios";
import { Endpoints } from "../config/endpoints";
import { PayrixPayment } from "../models/PayrixPayment";
import { IPaymentTransactionToPay, IProcessTransactionToPay } from "../models/Transaction";
import { ZoopPayment } from "../models/ZoopPayment";
import { Resources } from "../resources/Resources";
import BankSvg from './../assets/icons/svg/bank.svg';
import CardSvg from './../assets/icons/svg/card.svg';
import { IPaymentMethod, IPaymentMethodRequest, IPaymentMethodResponse, IPaymentMethodType, IPaymentMethodTypeCode, PlatformCode } from './../models/PaymentMethod';
require("dotenv").config();

interface IPaymentService {

  getPaymentMethodTypes(userId: string | undefined): Promise<IPaymentMethodType[]>;
  getTransactionSetupID(type: string): Promise<string>;
  addPaymentMethod(paymentMethod: IPaymentMethod): Promise<IPaymentMethodResponse>;
  processTransactionToPay(transactionToPay: IProcessTransactionToPay): Promise<IPaymentTransactionToPay>;
  processACHTransactionToPay(transactionToPay: any): Promise<IPaymentTransactionToPay>;
  updateTransaction(transaction: IPaymentTransactionToPay): Promise<IPaymentTransactionToPay>;
  rejectTransactionToPay(identifier: string): Promise<IPaymentTransactionToPay>;
  getTransactionToPay(paymentCode: string): Promise<IPaymentTransactionToPay>;
  getTransactionById(identifier: string): Promise<IPaymentTransactionToPay>;
}

const SpringPayServiceEndpoint = Endpoints.getEndpoint("REACT_APP_SPRINGPAY_ENDPOINT");

export const PaymentService: IPaymentService = {

  async getPaymentMethodTypes(userId: string | undefined): Promise<IPaymentMethodType[]> {
    const { data } = await axios.get<string[]>(
      SpringPayServiceEndpoint + "paymentTypes/" + userId
    );

    const paymentMethodTypes: IPaymentMethodType[] = data.map((pmt) => ({
      code: pmt as IPaymentMethodTypeCode,
      icon: pmt.indexOf('CARD') !== -1 ? CardSvg : BankSvg
    }));

    return Promise.resolve(paymentMethodTypes);
  },
  /**
   * @author Jesús Daniel Neira Lara - jneira
   * @description
   * Service to get the transaction setup ID
   * @param type Payment method type
   * @returns Transaction Setup ID
   */
  getTransactionSetupID(type): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(`${SpringPayServiceEndpoint}facade/WORLDPAY/transactionSetup`, {
          paymentMethodType: type
        })

        const { worldpayExpressResponse: { Transaction: { TransactionSetupID } } } = data;

        resolve(TransactionSetupID);

      } catch (error) {
        reject(error);
      }
    })
  },

  async addPaymentMethod(paymentMethod: IPaymentMethod): Promise<IPaymentMethodResponse> {
    let paymentMethodRequest: IPaymentMethodRequest | null = null;
    switch (paymentMethod.platform) {
      case PlatformCode.Zoop:
        const zoopPayment: ZoopPayment = new ZoopPayment(paymentMethod);
        paymentMethodRequest = await zoopPayment.addPaymentMethod();
        break;
      case PlatformCode.WorldPay:
        //const worldpayPayment: WorldpayPayment = new WorldpayPayment(paymentMethod);
        paymentMethodRequest = null;
        break;
      case PlatformCode.Payrix:
        const payrixPayment: PayrixPayment = new PayrixPayment(paymentMethod);
        paymentMethodRequest = await payrixPayment.addPaymentMethodAch();
        break;
      default:
        SpringLogger.error(`Platform ${paymentMethod.platform} was not implemented for the addPaymentMethod method`);
        throw Resources.get("PaymentMethod", "PlatformNotFound", "label");
    }
    const { data } = await axios.post(SpringPayServiceEndpoint + "accounts/paymentMethod", paymentMethodRequest);
    return data;
  },

  async updateTransaction(transaction: IPaymentTransactionToPay): Promise<IPaymentTransactionToPay> {
    const { data } = await axios.post(`${SpringPayServiceEndpoint}springPay/updateTransaction`, transaction);
    return data;
  },

  async processACHTransactionToPay(transactionToPay: any): Promise<IPaymentTransactionToPay> {
    const { data } = await axios.post(`${SpringPayServiceEndpoint}springPay/processACHTransactionToPay`, { ...transactionToPay });
    return data;
  },

  async processTransactionToPay(transactionToPay: IProcessTransactionToPay): Promise<IPaymentTransactionToPay> {
    const { data } = await axios.post(`${SpringPayServiceEndpoint}springPay/processTransactionToPay`, { ...transactionToPay });
    return data;
  },

  async rejectTransactionToPay(identifier: string): Promise<IPaymentTransactionToPay> {
    const { data } = await axios.post(`${SpringPayServiceEndpoint}springPay/rejectTransactionToPay`, { id: identifier });
    return data;
  },

  getTransactionToPay: async (paymentCode: string): Promise<IPaymentTransactionToPay> => {
    const { data } = await axios.get(`${SpringPayServiceEndpoint}springPay/transaction/ByCode/${paymentCode}`);
    return data;
  },

  getTransactionById: async (identifier: string): Promise<IPaymentTransactionToPay> => {
    const { data } = await axios.get(`${SpringPayServiceEndpoint}springPay/transaction/ByIdentifier/${identifier}`);
    return data;
  }
};