import axios from "axios";
import { Endpoints } from '../config/endpoints';
import { AuthenticationResult } from "../models/AuthenticationResult";
import { HttpInnerResponse } from "../models/HttpInnerResponse";

const IdentityServiceEndpoint = Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT");

interface ILoginService {
  login(username: string, password: string): Promise<AuthenticationResult>;
  authenticateSSOUser(ssoCookie: string): Promise<AuthenticationResult>;
  firstLoginActivateAuthenticate(username: string, password: string, newPassword: string, confirmPassword: string): Promise<AuthenticationResult>;
}

const LoginService: ILoginService = {
  login: async (
    username: string,
    password: string
  ): Promise<AuthenticationResult> => {
    const { data } = await axios.post<HttpInnerResponse>(
      IdentityServiceEndpoint + "api/security/authenticate",
      {
        login: username,
        password: password,
      }
    );
    return {
      statusCode: data.statusCode,
      accessToken: data.payload.user.accessToken,
      idToken: data.payload.user.data
    } as AuthenticationResult;
  },

  authenticateSSOUser: async (ssoCookie: string): Promise<AuthenticationResult> => {
    const { data }: any = await axios.post(`${IdentityServiceEndpoint}api/security/authenticateSSOUser/${ssoCookie}`);
    if (!data) return null!;
    return {
      statusCode: 200,
      accessToken: data.payload.user.accessToken,
      idToken: data.payload.user.data,
    } as AuthenticationResult;
  },

  firstLoginActivateAuthenticate: async (username: string, password: string, newPassword: string, confirmPassword: string): Promise<AuthenticationResult> => {
    try {
      const { data } = await axios.post<HttpInnerResponse>(
        IdentityServiceEndpoint + "user/firstLoginActivateAuthenticate",
        {
          login: username,
          password: password,
          newPassword: newPassword,
          newPasswordConfirmation: confirmPassword
        }
      );
      return {
        statusCode: data.statusCode,
        accessToken: data.payload.user.accessToken,
        idToken: data.payload.user.data
      } as AuthenticationResult;
    } catch (error) {
      throw error;
    }
  }
};

export default LoginService;
