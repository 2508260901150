import { SpringLogger } from "@spring-global/spring-logger-react";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import "../App.scss";
import Sidebar from "../components/Sidebar/Sidebar";
import { useAppDispatch, useAppState } from "../context/StateProvider";
import * as storage from "../context/Storage";
import { AuthenticationResult } from "../models/AuthenticationResult";
import LoginService from "../services/LoginService";
import { TokenIntrospectService } from "../services/TokenIntrospectService";

type CustomRouteProps = {
    showLeftSidebar: boolean;
}

type PrivateRouteProps = RouteProps & CustomRouteProps;

const ErrorPage = React.lazy(() => import('../pages/Errors/ErrorPage'));

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {

    const getShowLeftSidebar = () => {
        const query = new URLSearchParams(location.search);
        const embeddedMode = query.get("embeddedMode");
        if (embeddedMode === "true") return false;
        if (embeddedMode === "false") return true;
        return rest.showLeftSidebar;
    }

    const { identity } = useAppState();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [isLoading, setLoading] = useState(true);
    const [canOpenWindow, setCanOpenWindow] = useState(false);
    const [idToken, setIdToken] = useState(JSON.parse(storage.local.getItem("id_token")!));
    const [accessToken, setAccessToken] = useState(storage.local.getItem("accessToken"));
    const [showLeftSidebar] = useState(getShowLeftSidebar);

    const done = () => setLoading(false);
    const start = () => setLoading(true);
    const cleanSessionData = () => {
        dispatch({ type: "SET_IDENTITY", identity: { id: '', code: '', isAuthenticated: false, customerId: "" } });
        storage.local.removeItem('accessToken');
        storage.local.removeItem('id_token');
    }
    const checkSSOCookie = async (): Promise<void> => {
        try {
            const hqAppsCookie: string = new URLSearchParams(location.search).get('hqc')!;
            if (!hqAppsCookie) return;
            const response: AuthenticationResult = await LoginService.authenticateSSOUser(hqAppsCookie);
            if (response.statusCode === 200) {
                setIdToken(JSON.stringify(response.idToken));
                setAccessToken(response.accessToken);
                storage.local.setItem("accessToken", response.accessToken!);
                storage.local.setItem("id_token", JSON.stringify(response.idToken));
                dispatch({ type: "SET_IDENTITY", identity: { id: response.idToken.id, code: response.idToken.code, isAuthenticated: true, customerId: response.idToken.customerId } });
                setCanOpenWindow(true);
            }
        } catch (e) {
            cleanSessionData();
            SpringLogger.error(e);
        }
    };

    useEffect(() => {
        start()
        let verifySessionPromise = null;
        if (idToken && accessToken) {
            verifySessionPromise = TokenIntrospectService.verifySession(accessToken, { endpoint: rest.path ?? "" });
            verifySessionPromise.then(value => {
                setCanOpenWindow(value?.payload.valid);
                if (value.statusCode === 200) {
                    dispatch({ type: "SET_IDENTITY", identity: { id: idToken.id, code: idToken.code, isAuthenticated: true, customerId: idToken.customerId } })
                } else {
                    cleanSessionData();
                }
            });
        }


        if (!verifySessionPromise) {
            checkSSOCookie().finally(done);
        } else {
            verifySessionPromise.finally(() => {
                if (!canOpenWindow) {
                    checkSSOCookie().finally(done);
                } else {
                    done();
                }
            });
        }

        /**
         * @todo Check if really there is no need of another parameter
         */
        // eslint-disable-next-line
    }, [location.pathname]);

    const isPanelOverlay: boolean = false;

    let resultElem: JSX.Element;
    if (isLoading) {
        resultElem = <>
            {showLeftSidebar && <Sidebar className="app-sidebar" overlay={isPanelOverlay} />}
            <div className={`app-body ${isPanelOverlay ? "panel-overlay" : ""}`} >
                <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner animation="border" className="spinner"></Spinner>
                </div>
            </div>
        </>
    } else {
        if (identity && identity?.isAuthenticated) {
            if (!canOpenWindow || !Component ) {
                resultElem = <>
                    {showLeftSidebar && <Sidebar className="app-sidebar" overlay={isPanelOverlay} />}
                    <div className={`app-body ${isPanelOverlay ? "panel-overlay" : ""}`}>
                        <ErrorPage />
                    </div>
                </>
            } else {
                resultElem = <Route
                    {...rest}
                    render={(props) => (
                        <>
                            {showLeftSidebar && <Sidebar className="app-sidebar" overlay={isPanelOverlay} />}
                            <div className={`app-body ${isPanelOverlay ? "panel-overlay" : ""}`}>
                                <Component {...props} />
                            </div>
                        </>
                    )}
                />
            }
        } else {
            resultElem = <Redirect
            to={{ pathname: `/login/?ReturnURL=${location.pathname}` }}
        />
        }
    }

    return resultElem;
};

export default PrivateRoute;

