export interface IPaymentMethod {
  id?: number | undefined;
  userId: string;
  platform: PlatformCode;
  paymentMethodType?: IPaymentMethodType;
  card?: ICard | undefined;
  bankAccount?: IBankAccount | undefined;
  tokenID: string;
}

export type IPaymentMethodTypeCode = "DCARD" | "CCARD" | "ACH";

export interface IPaymentMethodType {
  code: IPaymentMethodTypeCode;
  icon?: string;
}

export interface ICard {
  name: string;
  number: string;
  cvv: string;
  expirationDate: string;
  isDefault: boolean;
  token?: string;
}

export interface IBankAccount {
  routingNumber: string;
  accountNumber: string;
  accountType: number;
  isChecking: boolean;
  isSaving: boolean;
  isDefault: boolean;
  customerId: string;
  token: string;
}

export interface IPaymentMethodRequest {
  id: string | null;
  userId: string;
  paymentTypeCode?: string;
  paymentMaskedNumber?: string;
  accountNumber?: string;
  routingNumber?: string;
  method?: number;
  token: string;
  expirationYear: string;
  expirationMonth?: string | undefined;
  cardLogo?: string | undefined;
  isDefault: boolean;
  status?: string | undefined;
  creationDate: string;
  platformCode: string;
  isTokenAssociated?: boolean;
}

export interface IPaymentMethodResponse {
  result: boolean;
  error?: {
    code?: string;
    message: string;
  };
}

export type TPaymentMethodStatus = 'PENDING' | 'SUCCS' | 'FAIL' | 'REVSD' | 'CANC'

export enum PlatformCode {
  Zoop = "ZOOP",
  WorldPay = "WORLDPAY",
  Payrix = "PAYRIX",
  Undefined = "",
}

export interface IAccountTypeOption {
  readonly value: string;
  readonly label: string;
  readonly isDisabled?: boolean;
}
