import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Resources } from '../../resources/Resources';
import { PaymentService } from '../../services/PaymentService';

interface WorldpayPageProps {

}

/**
 * @author Jesús Daniel Neira Lara - jneira
 * @description
 * Page to show the result after register a new payment method.
 * @returns Page
 */
const WorldpayPage: React.FC<WorldpayPageProps> = () => {
    const history = useHistory();
    //const { type } = useParams<{ type: string }>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    /**
     * @description
     * Function to get the result of worldpay and send it to the server
     * @returns 
     */
    const searchToObject = async () => {
        setError(false);

        try {
            const { location: { search } } = history;
            const elements = search.replace("?", "").split("&");

            if (elements.length > 0) {
                //let q: WorldpayResponse = {};
                let q: any = {};
                elements.forEach(e => {
                    const [key, value] = e.split("=");

                    q = { ...q, [key]: value };
                })

                await PaymentService.addPaymentMethod(q).then(console.log).catch(() => {
                    setError(true);
                });

            } else {
                return;
            }

        } catch (err) {

            setError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (loading) {
            searchToObject()
        }

        // eslint-disable-next-line
    }, [history, loading])

    return loading ? (
        <div className="container d-flex justify-content-center align-items-center">
            <Spinner animation="grow" />
        </div>
    ) : (
        <>
            {error ? (
                <div className="container">
                    <Alert variant="danger">{Resources.get("ErrorMessage", "ServerError", "label")}</Alert>
                </div>
            ) : (
                <div className="container">
                    <Alert variant="success">
                        <h1 className="h4">Success</h1>
                        <p>The new payment method has been added</p>
                    </Alert>
                </div>
            )}
        </>
    )
}

export default WorldpayPage;
