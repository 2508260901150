require("dotenv").config();

export class ServiceConfig {
  public static readonly gateway_base_url: string | undefined = process.env.REACT_APP_GATEWAY_BASE_URL;
  public static readonly language_base_url: string | undefined = process.env.REACT_APP_LANGUAGE_TOOLKIT;
  public static readonly zoop_base_url: string | undefined = process.env.REACT_APP_ZOOP_BASE_URL;
  public static readonly spring_logger: string | undefined = process.env.REACT_APP_SPRING_LOGGER_SENTRY_DSN;
  public static readonly payrix_base_url: string | undefined = process.env.REACT_APP_PAYRIX_BASE_URL;
  public static readonly payrix_key: string | undefined = process.env.REACT_APP_PAYRIX_KEY;
  public static readonly payrix_script_url: string | undefined = process.env.REACT_APP_PAYRIX_SCRIPT_URL;
  public static readonly payrix_merchant: string | undefined = process.env.REACT_APP_PAYRIX_MERCHANT;
}