import { ServiceConfig } from "./ServiceConfig";

const endPoints = require('./endpoints.json');

export class Endpoints {
  static getEndpoint(key: string): string {
    return `${ServiceConfig.gateway_base_url}` + endPoints[key];
  }
  static getLanguageEndpoint(): string {
    return `${ServiceConfig.language_base_url}`;
  }
  static getZoopEndpoint(): string {
    return `${ServiceConfig.zoop_base_url}`;
  }
  static getPayrixEndpoint(): string {
    return `${ServiceConfig.payrix_base_url}`;
  }
}