import axios from "axios";
import { HttpInnerResponse } from "../models/HttpInnerResponse";
import { IntrospectTokenResult } from "../models/IntrospectTokenResult";
import { Endpoints } from '../config/endpoints'

const IdentityServiceEndpoint = Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT");

interface ITokenIntrospectService {
  verifySession(accessToken: string, request: { endpoint: string | readonly string[] }): Promise<IntrospectTokenResult>;
}

export const TokenIntrospectService: ITokenIntrospectService = {

  verifySession: async (accessToken: string, request: { endpoint: string | string[] }): Promise<IntrospectTokenResult> => {
    const { data } = await axios.post<HttpInnerResponse>(
      IdentityServiceEndpoint + "security/verifyToken", {
      accessToken,
      request,
      type: 0
    });
    return {
      statusCode: data.statusCode,
      payload: {
        valid: data.payload.valid
      }
    } as IntrospectTokenResult;
  },
};

