import axios, { AxiosRequestConfig } from 'axios';
import * as storage from "../../context/Storage";
import { Endpoints } from "../../config/endpoints";

export const intercept = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = storage.local.getItem("accessToken");
    config.params && Object.keys(config.params).forEach((key) => {
      if (config.params[key] === '' || config.params[key] === undefined) {
        delete config.params[key];
      }
    });

    if (config.url?.includes(Endpoints.getPayrixEndpoint() || Endpoints.getZoopEndpoint())) return config;
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  }, (error) => {
    return Promise.reject(error);
  });
}